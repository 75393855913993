<template>
  <div class="px-6 py-4" style="max-height: 78vh; overflow-y: auto;">
    <div class="mo-sidebar-heading pb-4">Lease Summary</div>

      <HbInfoCard
        v-if="! contact?.national_account_id"
        class="mb-4 hb-text-overflow-wrap" 
        type="contact"
        :title="computedContactTitle"
        :is-military="selectedTenant.Military && selectedTenant.Military.active && selectedTenant.Military.active == 1"
        :status="selectedTenant.status ? selectedTenant.status : undefined"
        :phone="computedContactPhone | formatPhone"
        :phone-is-verified="computedContactPhoneVerified"
        :email="selectedTenant.email ? selectedTenant.email : undefined"
        :email-is-verified="selectedTenant.email_verified"
        :contact-address="computedContactAddress"
        :key="selectedTenant.status"
        :contact-is-business="selectedTenant.rent_as_business"
        @click="viewEntity('contact', selectedTenant.id)"
      />
            <!-- MINI PROFILE National Account-->
            <hb-header one-action :padding="false"  v-if="contact?.national_account_id">
              <template v-slot:left>
                <div class="pr-4 mb-2">
                  <hb-mini-profile type="account" :title="contact.Business.name" :status="contact.status ? contact.status : undefined
                    " :address="computedBusinessAddress" :id-verified="contact.email_verified
                      ? contact.email_verified
                      : undefined
                       " />
                </div>
              </template>
            </hb-header>

    <div class="ls-bottom-content px-4 py-6" v-if="step===3 && !contact?.national_account_id">
      <v-row class="hb-font-body">Outstanding Charges:</v-row>
      <v-row class="pb-3 hb-font-body hb-text-night">{{moveOutCalc?.totalDue | formatMoney}} </v-row>
      <v-row class="hb-font-body">Pending Refund:</v-row>
      <v-row class="pb-3 hb-font-body hb-text-night">{{moveOutCalc?.total_to_refund | formatMoney}}</v-row>
      <v-row class="hb-font-header-3-medium hb-text-night">Overall Balance:</v-row>
      <v-row class="pb-3 hb-font-header-3-medium hb-text-night">{{ computedOverallBalance | formatMoney}}</v-row>
      <!-- <v-row v-if="! contact?.national_account_id">Tenant Lifetime Value:</v-row>
      <v-row class="pb-3" v-if="! contact?.national_account_id">{{ leaseMatrices.lifetime_payments | formatMoney }}</v-row>
      <v-row v-if="! contact?.national_account_id">Account Balance:</v-row>
      <v-row v-if="! contact?.national_account_id">${{accountBalance}}</v-row> -->
    </div>
  </div>
</template>
<script type="text/babel">
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
import api from "../../../assets/api";
import { EventBus } from "../../../EventBus";
export default {
  name: "LeaseSummary",
  data: () => ({
    tenant:{}
  }),
  props: ["lease", "contact", "access", "leaseMatrices", "step"],
  mounted() {},
  filters: {
    formatDate(value) {
      if (!value) return null;
      return moment(value).format("MMMM DD, YYYY");
    }
  },
  computed: {
    ...mapGetters({
          contacts: 'onBoardingStore/contact',
          moveOutCalc: 'paymentsStore/getMoveOutCalc'
    }),
    computedOverallBalance() {
      const amount_due = this.moveOutCalc?.message?.amount_due ?? 0;
      const refund_amount = this.moveOutCalc?.message?.refund_amount ?? 0;
      let overall_balance =  amount_due - refund_amount
      return overall_balance
    },
    computedBusinessAddress() {
      if (
        this.contact.Business &&
        this.contact.Business.Address
      ) {
        const address_1 = this.contact.Business.Address.address
          ? this.contact.Business.Address.address
          : "";
        const address_2 = this.contact.Business.Address.address2
          ? this.contact.Business.Address.address2
          : "";

        let address = address_1;
        if (address_2 !== "") {
          if (address !== "") {
            address += ", ";
          }
          address += address_2;
        }

        if (address !== "") {
          address += ", ";
        }

        address += this.$options.filters.formatAddress(this.contact.Business.Address);

        return address;
      } else {
        return undefined;
      }
    },
    selectedTenant: {
      get() {
          return this.tenant;
      },
      set(value) {
          this.tenant = value;
      }
    },
    selectedPhone() {
      let phone = this.selectedTenant.Phones.length
        ? this.selectedTenant.Phones[0].phone
        : "";
      return phone;
    },
    accessCode() {
      return this.$props.access.pin ? this.$props.access.pin : undefined;
    },
    accountBalance() {
      return this.$props.lease.balance
        ? this.$props.lease.balance
        : 0;
    },
    computedContactPhoneVerified() {
          if(this.selectedTenant.Phones && this.selectedTenant.Phones[0] && this.selectedTenant.Phones[0].phone_verified && this.selectedTenant.Phones[0].phone_verified == 1){
              return true;
          } else {
              return false;
          }
    },
    computedContactTitle() {
      if(this.selectedTenant && this.selectedTenant){
        return (this.selectedTenant.first ? this.selectedTenant.first : '') + (this.selectedTenant.middle ? ' ' + this.selectedTenant.middle : '') + (this.selectedTenant.last ? ' ' + this.selectedTenant.last : '');
      } else {
        return undefined;
      }
    },
    computedContactPhone() {
      if(this.selectedTenant && this.selectedTenant && this.selectedTenant.Phones && this.selectedTenant.Phones[0] && this.selectedTenant.Phones[0].phone){
        return this.selectedTenant.Phones[0].phone;
      } else {
        return undefined;
      }
    },
    computedContactAddress() {
      if(this.selectedTenant && this.selectedTenant && this.selectedTenant.Addresses && this.selectedTenant.Addresses[0] && this.selectedTenant.Addresses[0].Address){
        return (this.selectedTenant.Addresses[0].Address.address ? this.selectedTenant.Addresses[0].Address.address : '') + (this.selectedTenant.Addresses[0].Address.address2 ? ' ' + this.selectedTenant.Addresses[0].Address.address2 + ', ' : '') +  this.$options.filters.formatAddress(this.selectedTenant.Addresses[0].Address); 
      } else {
        return undefined;
      }
    },
  },
  methods: {
    ...mapMutations({
      setContact: 'onBoardingStore/setContact'
    }),
    viewEntity(entity, entity_id){
      let params = {};
      params = {
        component: entity,
        props: {}
      };
      params.props[entity + '_id'] = entity_id;
      this.$store.dispatch('navigationStore/openSlideOut', params); 
    },
    setContactDetail(contact){
          this.selectedTenant = contact;
          this.setContact(contact);
    }
  },
  destroyed() {
        EventBus.$off('tenant_updated');
  },
  created() {
    this.selectedTenant = this.contacts?.id ? this.contacts : this.$props.contact;
    EventBus.$on('tenant_updated', this.setContactDetail);
  },
};
</script>

<style scoped lang="scss">
.mo-sidebar-heading {
  font-weight: 500;
  font-size: 16px;
}
.ls-bottom-content {
  line-height: 20px;
  color: #637381;
}
.unit-number {
  vertical-align: text-bottom;
}
.link-color {
  color: #306fb6 !important;
}
</style>