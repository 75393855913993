<template>
  <div :class="{ 'unitComponentStatusFlag':unitComponentStatusFlag }">
    <v-row v-if="property_id" no-gutters class="ml-6 mt-5 mb-0"><span class="hb-font-header-2-medium" style="color: #101318;"> Select a Space</span></v-row>
    <v-row no-gutters class="ml-6 mb-0">
            <v-col class="mr-auto">
              <HbPropertySelector
                :items="properties"
                item-text="name"
                item-value="id"
                label="Select A Property"
                v-model="property_id"
                @change="updateProperty"
                :menu-props="{ contentClass: 'facility-selector-dense-menu-fix' }"
              >
              </HbPropertySelector>
            </v-col>
    </v-row>
    <v-row justify="center" align="center" v-if="loading">
      <v-col class="text-center ma-6">
        <v-progress-circular indeterminate color="primary" ></v-progress-circular>
      </v-col>
    </v-row>
    <div v-else v-show="property_id && property_id !== 'all-property'">

      <div>
        <hb-tabs
          v-show="!this.unit.id"
          class="pl-6"
          :background-color="'#F9FAFB'"
        >
          <v-tab
            @click="active_tab = 'spaces'"
          >
            Space List
          </v-tab>
          <!-- <v-tab
            @click="active_tab = 'categories'"
          >
            Website
          </v-tab> -->
          <v-tab
            @click="active_tab = 'property'"
          >
            Property Info
          </v-tab>
          <!--              <v-tab  @click="active_tab = 'map'">Map</v-tab>-->
        </hb-tabs>
      </div>
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <div v-show="active_tab == 'spaces'" class="filter-section mb-3">
          <v-row no-gutters>
            <filter-dropdown-menu
              :filter_data="filter_data"
              :search="search"
              :reset="false"
              :location="location"
              :isNational="isNational"
              @resetFilters = "resetFilters"
              @setValues = "setValues"
              @spaceStatusChange = "changeSpaceStatus"
              @showMapView="showMapView"
              >
            </filter-dropdown-menu>
          </v-row>
      </div>
      <div class="unit-section scrollable-content">
        <div v-if="!this.unit.id && active_tab === 'categories'">
          <category-selector :search="search" :property="property" @setUnit="setUnit"></category-selector>
        </div>
        <div v-if="!this.unit.id && active_tab === 'property'">
          <property-info :isNational="isNational"></property-info>
        </div>
        <div v-if="!$store.getters.leaseUnit.id && active_tab === 'spaces'">
          <unit-selector :search="search" :property="property" @setUnit="setUnit"></unit-selector>
        </div>
        <div v-if="!$store.getters.leaseUnit.id && active_tab === 'map'">
          <h2 class="title ml-1">Select A Space On The Map</h2><br />
        </div>

        <div v-if="$store.getters.leaseUnit.id">
          <!--                <v-btn v-if="$store.getters.reservation.reservation_id || !$store.getters.lease.id " text @click="unsetUnit"><v-icon>mdi-chevron-left</v-icon><small>  Back to List</small></v-btn>-->
          <!--                <lease-info :unit_id="$store.getters.leaseUnit.id"></lease-info>-->
        </div>
      </div>

      <!-- <div class="new-tenant-walkthrough-footer">
        <hb-bottom-action-bar
          @close="$emit('close')"
          :top-border="false"
        >
        <template v-slot:left-actions>
          <help-juice />
        </template>
          <template v-slot:right-actions>
            <hb-btn
              v-show="contact.id"
              color="secondary"
              :disabled="isLoading($options.name)"
              @click="$emit('retire_lead')"
            >
              Retire Lead
            </hb-btn>

           <v-btn v-if="!$store.getters.lease.id" class="mr-1" color="primary"  :disabled="!$store.getters.leaseUnit.id || isLoading($options.name)" @click.prevent.stop="reserve">Reserve</v-btn>&nbsp;
           <v-btn color="primary" :disabled="!$store.getters.leaseUnit.id || isLoading($options.name)" @click.prevent.stop="saveLease">Move In</v-btn>
          </template>
        </hb-bottom-action-bar>
      </div> -->
    </div>
    <v-dialog v-if="property_id" v-model="mapView"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
   >
    <v-card>
          <v-toolbar
            color="#E0F5F5"
            elevation="0"
            height="56px"
          >
            <v-toolbar-title class="hb-font-header-2-medium	">Property Map</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <HbBtn icon tooltip="Close" mdi-code="mdi-close" class="mt-2" @click="mapView = false"/>
            </v-toolbar-items>
          </v-toolbar>
          <v-row v-if="mapView" style="flex: 1; position: relative;">
            <MapViewer
            :spacesWithCoordinates="spacesWithCoordinates"
            :assets="assets"
            :floors="floors"
            :currentFloor="parseInt(currentFloor)"
            :spaceCount="spaceCount"
            :mapInitialLoad="mapInitialLoad"
            />
          </v-row>
          
    </v-card>
   </v-dialog>
   <div class="px-6">
   <hb-empty-state v-if="!property_id || property_id === 'all-property'" message="Please select a Property to continue."></hb-empty-state>
   </div>
  </div>
  
</template>

<script type="text/babel">
    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment';

    import UnitSelector from './UnitSelector';
    import PropertyInfo from './PropertyInfo.vue';
    import CategorySelector from './CategorySelector';
    // import MapSelector from './MapSelector';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import { EventBus } from '../../../EventBus.js';
    import LeaseInfo from "./LeaseInfo";

    import MapViewer from "../../property_map/MapViewer.vue";
    import FilterDropdownMenu from '../../FilterDropdownMenu.vue'
    import { mapGetters } from 'vuex';
    export default {
        name: 'UnitSelection',
        components: {
            LeaseInfo,
            Status,
            UnitSelector,
            CategorySelector,
            HbDatePicker,
            FilterDropdownMenu,
            PropertyInfo,
        },
        data: () => ({
            sizes: [],
            prices: [],
            active_tab: 'spaces',
            filter_data: [
                {
                    open: false,
                    label: 'Type',
                    items: [],
                    data: []
                },
                {
                    open: false,
                    label: 'Size',
                    items: [],
                    data: []
                },
                {
                    open: false,
                    label: 'Price',
                    items: ['< $50', '$50 - $100', '$100 - $150', '$150 - $300', '> $300'],
                    data: ''
                },
                {
                    open: false,
                    label: 'Floor',
                    items: [],
                    data: []
                },
                {
                    open: false,
                    label: 'Amenities',
                    items: [],
                    data: []
                },
            ],
            panel: 0,
            confirm: false,
            edit_property: false,
            property_id: '',
            search: {
                search: '',
                type: [],
                price: [],
                size: [],
                amenities: [],
                floor: [],
                status: ['Available'],
              },
            mapView: false,
            spaces: [],
            assets: [],
            floors: [],
            currentFloor: 1,
            spacesWithCoordinates: [],
            mapFilters: {},
            spaceCount: 1,
            currentProperty: "",
            mapProperties: [],
            mapExportAvailable: false,
            mapInitialLoad: false,
            dataFetchingError: "",
            propertyMapFilters: [
              {
                label: "Space Type",
                key: "unit_type",
                sortable: true,
                width: 118,
                column_type: "string",
                agg_method: "Count",
                format: "",
                fixed: false,
                input: "multi-select",
                options: [],
                search: [],
                group: "space",
              },
              {
                label: "Space Size",
                key: "unit_size",
                sortable: true,
                width: 113,
                column_type: "string",
                agg_method: "Count",
                format: "",
                fixed: false,
                input: "multi-select",
                options: [],
                search: [],
                group: "space",
              },
              {
                label: "Space Status",
                key: "unit_status",
                sortable: true,
                width: 168,
                column_type: "status",
                agg_method: "Count",
                format: "",
                fixed: false,
                input: "multi-select",
                options: [
                  "Available",
                  "Leased",
                  "Pending",
                  "On Hold",
                  "Offline",
                  "Reserved",
                  "Overlocked",
                  "Remove Overlock",
                  "To Overlock",
                  "Future Leased",
                  "Deactivated",
                ],
                search: [],
                group: "space",
              },
            ],
            location:"LEAD_ON_BOARDING",
        }),
        props: ['loading','unitComponentStatusFlag','propertyId','isNational'],
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                property: 'onBoardingStore/property',
                send_quote_property: 'sendQuoteStore/send_quote_property',
                lease: 'onBoardingStore/lease',
                contact: 'onBoardingStore/contact',
            }),
            unit: {
                get() { return this.$store.getters['onBoardingStore/configuration_unit'] } ,
                set(val){  return this.$store.commit('onBoardingStore/configuration_unit', val ) }
            },
        },
        async created(){
            await this.$store.dispatch('filterStore/getCategories');
            await this.getSizes();
            await this.getFloors();
            await this.getAmenities();
            await this.getUnitTypesFilterOptions();
            this.$emit('update:loading', false)
            EventBus.$on('lead-question-space-filter', this.setFilterValues)
        },
        mounted() {
          this.property_id = this.isNational
            ? this.propertyId  // Sent from tenant profile
            : this.property.id;
          // this.updateMap();
        },
        methods:{
            resetFilters(){
                for(let i = 0; i< this.filter_data.length; i++){
                    this.filter_data[i].data = [];
                }
                this.search.search = '';
                this.search.price = [];
                this.search.size = [];
                this.search.amenities = [];
                this.search.floor = [];
                this.search.status = this.search.status ?? [];
            },
            changeSpaceStatus(value) {
              this.search.status = value ? [value] : [];
            },
            async getSizes(){
                let r = await api.get(this, api.UNITS +  'options', { property_id: this.property.id   });
                this.filter_data[1].items = r.options.map(o => o.width + "' x " + o.length + "'" );
            },
            async getFloors(){
                let r = await api.get(this, api.UNITS + 'floors', { property_id: this.property.id }); // changed to this.property.id because property_id might be 'all-property'
                this.filter_data[3].items = r.floors.map( o => o.name );
            },
            async getAmenities(){
                let r = await api.get(this, api.UNITS + 'amenities' + '/all', { property_id: this.property.id });
                this.filter_data[4].items= r.amenities.map( o => o.name );
            },
            setValues(mi){
                this.search[mi.label.toLowerCase()] = mi.data;
                if(mi.label.toLowerCase() == 'floor'){
                  this.changeFloor(this.search?.floor[0])
                }
                mi.open = false;
            },
            setFilterValues(spaceFilter){
              this.search.size = spaceFilter.size ? [spaceFilter.size] : [];
              this.search.price = spaceFilter.price ? [spaceFilter.price] : [];
              this.search.floor = spaceFilter.floor ? [spaceFilter.floor] : [];
              this.search.type = spaceFilter.type ? [spaceFilter.type] : [];
              this.search.amenities = spaceFilter.amenities ? spaceFilter.amenities :[];
              this.search.status = this.search.status ?? ['Available']
            },
            setSearchParams(){

            },
            async updateProperty(propertyId) {
              await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === this.property_id));
              await this.$store.dispatch('sendQuoteStore/getPropertyInfo', this.properties.find(p => p.id === propertyId));
              this.edit_property = false;
            },
            async setUnit(unit_id){
                this.$emit('setUnit',  {unit_id: unit_id});
            },
            showMapView() {
              this.mapView = true;
              let mapFilters = {
                location: 'leadFlow',
                unit_size: this.search.size,
                unit_type: this.search.type,
                unit_status: this.search.status,
              }
              this.onApplyPropertyMap(mapFilters)
            },
            updateMap() {
              api
                .get(this, api.PROPERTY_MAP + this.property_id + "/searchUnits")
                .then((res) => {
                  let { units } = res;
                  this.spaces = units;
                  this.spacesWithCoordinates = units
                    .filter((space) => space.y !== null && space.x !== null)
                    .map((v) => {
                      let space = { ...v, color: "#919EAB", filterMatch: true };
                      if (space.floor !== null && !isNaN(parseInt(space.floor))) {
                        space.floor = parseInt(space.floor);
                      }
                      return space;
                    });
                  this.setFloors();
                  this.updateSpaceCount();
                  this.mapInitialLoad = true;
                  this.isLoading = false;
                });
              api
                .get(this, api.PROPERTY_MAP + this.property_id + "/assets")
                .then((res) => {
                  let { assets } = res;
                  this.assets = assets;
                  this.spacesWithCoordinates = assets.filter(
                    (asset) => asset.x && asset.y
                  );
                  this.assets = res.assets;
                });
            },
            setFloors() {
              // this.isLoading = true;
              this.floors = [
                ...new Set(
                  this.spacesWithCoordinates
                    .filter(
                      (space) => space.floor !== null && !isNaN(parseInt(space.floor))
                    )
                    .map((space) => parseInt(space.floor))
                    .sort()
                ),
              ];
              this.currentFloor = this.floors.length ? parseInt(this.floors[0]) : 1;
            },
            updateSpaceCount() {
              this.spaceCount = this.spacesWithCoordinates.filter(
                (space) =>
                  space.filterMatch === true && space.floor == this.currentFloor
              ).length;
            },
            async onApplyPropertyMap(search) {
              let { unit_size, unit_status, unit_type } = search;
              this.spacesWithCoordinates.forEach((space, i) => {
                // Filtering: A space will be a match (and not filtered out) if
                // 1. A filter category the space is being compared to has nothing to filter against
                // 2. The space attribute (size,status,type) matches one of the filter options for that filter category
                // If a space matches `true` for all filter categories then it is a match and not deemed 'filtered-out'.
                let matchesSizeFilter =
                    !unit_size.length || unit_size.indexOf(space.label) > -1,
                  matchesStatusFilter =
                    !unit_status.length || unit_status.indexOf(space.state) > -1,
                  matchesTypeFilter =
                    !unit_type.length || unit_type.indexOf(space.type) > -1;
                this.spacesWithCoordinates[i].filterMatch =
                  matchesSizeFilter && matchesStatusFilter && matchesTypeFilter;
                return;
              });
              this.updateSpaceCount();
            },
            changeFloor(ev) {
              this.currentFloor = parseInt(ev);
              this.updateSpaceCount();
            },
            updateSpaceCount() {
              this.spaceCount = this.spacesWithCoordinates.filter(
                (space) =>
                  space.filterMatch === true && space.floor == this.currentFloor
              ).length;
            },
            fetchPropertyList() {
              this.mapProperties = this.selectedProperties;
              if (this.mapProperties.length && this.mapInitialLoad) {
                this.currentProperty = this.mapProperties[0].id;
                this.updateMap();
              }
            },
            getUnitTypesFilterOptions(){
              this.filter_data[0].items = this.getUnitTypes();
            }
        },
        watch: {
            "property.id"() {
                this.resetFilters();
                this.getSizes();
                this.getFloors();
                this.getAmenities();
                this.getUnitTypesFilterOptions();
                this.property_id = this.property.id;
                this.updateMap();
                this.search.status = ['Available']
            },
        }
    }
</script>


<style scoped>
  .tab-container{
    padding: 0;
    background: none;
    border-bottom: 1px solid #E1E6EA
  }
  .new-tenant-walkthrough .v-tabs,
  .new-tenant-walkthrough .v-tabs .v-tabs-bar{
    background: #F9FAFB;
  }

  .new-tenant-walkthrough-footer{
    /* padding: 3px 0 3px 0; */
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }
  .unit-section{
    padding: 0px 24px 0px 24px;
  }

  .unit-confirm-icon .v-list-item__icon {
    text-align: center;
    background: white;
    padding: 25px;

  }

  .v-expansion-panel-content .form-label{
    background: #F4F6F8;
    padding: 20px;
    font-weight: bold;
  }
  .row {
    margin-bottom: 10px;
  }
  .filter-section{
    padding: 14px 24px 0px 24px;
  }
  .grid-container {
  margin-top: 1px;
  }
  .v-progress-linear.mapProgressBar.theme--light {
  position: absolute;
  transform: translateY(-4px);
  }
  .map-wrap{
    height: 85vh;
  }
  /* .v-dialog__content{
    z-index:10 !important
  } */
  

</style>

