<template>
  <div >
    <div v-if="loading || !form.unit_id" >
      <v-row justify="center" align="center">
        <v-col class="text-center ma-6">
          <v-progress-circular indeterminate color="primary" ></v-progress-circular>
        </v-col>
      </v-row>
    </div>
    <div v-else class="unit-section scrollable-content">
      <div class="lease-info">
        <div>
          <hb-breadcrumb @click="$emit('goBack')" v-if="!unitComponentStatusFlag || isNational">Back to Space List</hb-breadcrumb>
        </div>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <v-row class="ml-0 mt-3 py-2">
          <hb-mini-profile
            v-if="configuration_unit"
            style="max-width:100%"
            type="space"
            disable-avatar-click
            disable-click
            :title="configuration_unit.number ? configuration_unit.number : undefined"
            :status="configuration_unit.state ? configuration_unit.state : undefined"
            :space-type="getUnitTypeLabel(configuration_unit)"
            :space-category="configuration_unit.label ? configuration_unit.label : undefined"
            :space-featured-amenity="configuration_unit && configuration_unit.Category && configuration_unit.Category.name ? configuration_unit.Category.name : undefined"
            :space-property-info="computedPropertyInfo"
        ></hb-mini-profile>
        </v-row>
        <hb-notification
                v-if="showAPWDates && APWInfo"
                type="guidance"
                @close="APWInfo=false"
                >
                Advanced Reservations and Rentals Management setting is Active.
                </hb-notification>
        <hb-notification
                v-if="inStoreTime && cutOffTimeInfo"
                type="guidance"
                @close="cutOffTimeInfo = false"
                >
                {{ isBeforeTime(inStoreTime) ? `It's past Cut-Off Time, Cut-Off time : ${inStoreTime }`:`Cut-Off time is active, Cut-Off time : ${inStoreTime }` }}
        </hb-notification>
        <v-expansion-panels v-model="panel">
          <hb-expansion-panel
            no-top-margin
            expand-icon-off
            v-if="isNational"
          >
            <template v-slot:title>
              Lease Details
            </template>
            <template v-slot:content>
              <hb-form label="Lease Period">
                <template>
                  {{ form.type }}
                </template>
              </hb-form>
              <hb-form
                label="Bill On"
                required
              >
                <template>
                    {{ daysOfMonth[form.bill_day - 1].text }} of month
                </template>
              </hb-form>
              <hb-form
                label="Base Rent"
                required
                :editable="isNational" :active="'Base Rent'" :hide-actions="true"
              >
              <template v-slot:display >
              <hb-icon mdi-code="mdi-currency-usd" color="#C4CDD5" ></hb-icon>
                 {{ form.rent }}
              </template>
              <template v-slot:edit>
                <HbTextField
                  v-model.lazy="form.rent"
                  v-validate="'required|decimal:2|min_value:0'"
                  placeholder="Enter Base Rent"
                  prepend-inner-icon="mdi-currency-usd"
                  data-vv-name="rent"
                  data-vv-scope="info"
                  data-vv-as="Base Rent"
                  :error="errors.collect('info.rent').length > 0"
                  :disabled="!isPermissionToEdit"
                  name="lease-base-rent"
                  id="lease-base-rent"
                  autocomplete="cc-lease-base-rent"
                  @input="baseRentChanged = true"
                />
              </template>       
              </hb-form>
              <hb-form
                :label="isFixedLength ? 'Start Date' : 'Move In Date'"
                required
                :editable="true" :active="isFixedLength ? 'Start Date' : 'Move In Date'" :hide-actions="true"
              >
              <template v-slot:display>
                {{ form.start_date }}

              </template >
              <template v-slot:edit>
                <div style="margin-top:-23px;">
                  <HbDatePicker
                    :min="setMinDateForBackDateMoveIn"
                    v-model.lazy="form.start_date"
                    label="MM/DD/YYYY"
                    v-validate="'required|min:1'"
                    :max="form.end_date || apwEndDate"
                    :clearable="true"
                    clear-icon="mdi-close-circle"
                    :single-line="true"
                    :solo="false"
                    :data-vv-as="isFixedLength ? 'Start Date' : 'Move In Date'"
                    name="start_date"
                    data-vv-scope="info"
                    :error="errors.collect('info.start_date').length > 0"
                    :change="updateBillDay(form.start_date)"
                  />
                  <div v-if="showAPWDates">
                    <span class="hb-text-light apw-days-container" >
                      <div>
                        Reservation Window: <span>
                          {{ apwPlans?.reservationPlan?.plan?.days == 0 ? 'No Reservations' : `${apwPlans?.reservationPlan?.plan?.days} ${apwPlans?.reservationPlan?.plan?.days > 1 ? 'days' : 'day'}` }} 
                          {{ apwPlans?.reservationPlan?.plan?.days > 0 ? (apwPlans?.reservationPlan?.dates) : '' }} 
                        </span>
                      </div>
                      <div>
                        Rental Window: <span>
                          {{ apwPlans?.rentalPlan?.plan?.days == 0 ? 'Today: No Advanced Rentals' : `${apwPlans?.rentalPlan?.plan?.days} ${apwPlans?.rentalPlan?.plan?.days > 1 ? 'days' : 'day'}` }} 
                          {{ apwPlans?.rentalPlan?.plan?.days > 0 ? (apwPlans?.rentalPlan?.dates) : '' }} 
                        </span>

                      </div>
                    </span>
                  </div>
                </div>
                  
              </template>
              </hb-form>
              <hb-form label="Discount" v-if="!form.payment_cycle || form.payment_cycle.toLowerCase() === 'monthly' || hasPermission('payment_cycle_discounts')"
              :editable="!isNational" :active="'Discount'" 
              >
              <template v-slot:display>
              {{ discounts.length && form?.discount_id ? discounts.find(item => item.id == form.discount_id)?.name : unitComponentStatusFlag ? "No Discount Selected": "Click to Select a Discount" }}
              </template>
              </hb-form>
              </template>
          </hb-expansion-panel>
          <hb-expansion-panel
            no-top-margin
            expand-icon-off
            v-else
          >
            <template v-slot:title>
              Lease Details
            </template>
            <template v-slot:content>
              <hb-form label="Lease Period" :editable="!unitComponentStatusFlag" :active="'Lease Period'" :hide-actions="true" :edit="!unitComponentStatusFlag">
              <template v-slot:display>
              {{ form.type }}
              </template >
              <template v-slot:edit>
                <HbSelect 
                  v-model.lazy="form.type"
                  :items="leaseTypes"
                  placeholder="Select Lease Period"
                  :clearable="false"
                />  
              </template>
              </hb-form>
              <hb-form
                :label="isFixedLength ? 'Start Date' : 'Move In Date'"
                required
                :editable="!unitComponentStatusFlag" :active="isFixedLength ? 'Start Date' : 'Move In Date'" :hide-actions="true"
              >
              <template v-slot:display>
              {{ form.start_date }}
              
              </template >
              <template v-slot:edit>
                <div style="margin-top:-23px;">
                  <HbDatePicker
                    :min="setMinDateForBackDateMoveIn"
                    v-model.lazy="form.start_date"
                    label="MM/DD/YYYY"
                    v-validate="'required|min:1'"
                    :max="form.end_date || apwEndDate"
                    :clearable="true"
                    clear-icon="mdi-close-circle"
                    :single-line="true"
                    :solo="false"
                    :data-vv-as="isFixedLength ? 'Start Date' : 'Move In Date'"
                    name="start_date"
                    data-vv-scope="info"
                    :error="errors.collect('info.start_date').length > 0"
                    :change="updateBillDay(form.start_date)"
                  />
                  <div  v-if="showAPWDates">
                    <span class="hb-text-light apw-days-container" >
                      <div>
                        Reservation Window: <span>
                          {{ apwPlans?.reservationPlan?.plan?.days == 0 ? 'No Reservations' : `${apwPlans?.reservationPlan?.plan?.days} ${apwPlans?.reservationPlan?.plan?.days > 1 ? 'days' : 'day'}` }} 
                          {{ apwPlans?.reservationPlan?.plan?.days > 0 ? (apwPlans?.reservationPlan?.dates) : '' }} 
                        </span>
                      </div>
                      <div>
                        Rental Window: <span>
                          {{ apwPlans?.rentalPlan?.plan?.days == 0 ? 'Today (No Advanced Rentals)' : `${apwPlans?.rentalPlan?.plan?.days} ${apwPlans?.rentalPlan?.plan?.days > 1 ? 'days' : 'day'}` }} 
                          {{ apwPlans?.rentalPlan?.plan?.days > 0 ? (apwPlans?.rentalPlan?.dates) : '' }} 
                        </span>

                      </div>
                    </span>
                  </div>
                </div>
                  
              </template>
              </hb-form>

              <hb-form
                v-show="isFixedLength"
                label="End Date"
                :required="isFixedLength"
                :editable="!unitComponentStatusFlag" :active="'End Date'" :hide-actions="true"
              >
              <template v-slot:display>
                  {{ form.end_date }}
              </template>
              <template v-slot:edit>
                <div style="margin-top:-23px;">
                  <HbDatePicker
                    :disabled="unitComponentStatusFlag"
                    v-model.lazy="form.end_date"
                    v-validate="isFixedLength ? 'required' : ''"
                    label="MM/DD/YYYY"
                    :clearable="true"
                    clear-icon="mdi-close-circle"
                    :single-line="true"
                    :solo="false"
                    :min="form.start_date"
                    data-vv-as="End Date"
                    name="end_date"
                    data-vv-scope="info"
                    :error="errors.collect('info.end_date').length > 0"
                  />
                </div>
              </template>
              </hb-form>

              <hb-form
                label="Bill On"
                required
                :editable="!unitComponentStatusFlag" :active="'Bill On'" :hide-actions="true"
              >
                <template v-slot:display>
                    {{ daysOfMonth[form.bill_day - 1].text }}
                </template>
                <template v-slot:edit>
                <span @click="showNotificationModal('edit_bill_day')" >
                  <HbSelect
                    v-model.lazy="form.bill_day"
                    v-validate="'required'"
                    :items="daysOfMonth"
                    item-text="text"
                    item-value="value"
                    placeholder="Select Bill On Day"
                    :clearable="false"
                    data-vv-scope="info"
                    data-vv-name="bill_on"
                    data-vv-as="Bill on"
                    :error="errors.collect('info.bill_on').length > 0"
                    :disabled="!hasPermission('edit_bill_day_onboarding_process')"
                  />
                </span>
                <HbCheckbox
                  v-model.lazy="form.send_invoice"
                  id="consecutive_pay"
                  name="consecutive_pay"
                  label="Email monthly invoice"
                />
                    
                </template>
              
              </hb-form>

              <hb-form
                label="Base Rent"
                required
                :editable="!unitComponentStatusFlag" :active="'Base Rent'" :hide-actions="true"
              >
              <template v-slot:display >
              <hb-icon mdi-code="mdi-currency-usd" color="#C4CDD5" ></hb-icon>
                 {{ form.rent }}
              </template>
              <template v-slot:edit>
                <HbTextField
                  v-model.lazy="form.rent"
                  v-validate="'required|decimal:2|min_value:0'"
                  placeholder="Enter Base Rent"
                  prepend-inner-icon="mdi-currency-usd"
                  data-vv-name="rent"
                  data-vv-scope="info"
                  data-vv-as="Base Rent"
                  :error="errors.collect('info.rent').length > 0"
                  :disabled="!isPermissionToEdit || unitComponentStatusFlag"
                  name="lease-base-rent"
                  id="lease-base-rent"
                  autocomplete="cc-lease-base-rent"
                  @input="baseRentChanged = true"
                />
                  
              </template>       

<!-- 
                <HbTextField
                  v-model.lazy="form.rent"
                  v-validate="'required|decimal:2|min_value:0'"
                  placeholder="Enter Base Rent"
                  prepend-inner-icon="mdi-currency-usd"
                  data-vv-name="rent"
                  data-vv-scope="info"
                  data-vv-as="Base Rent"
                  :error="errors.collect('info.rent').length > 0"
                  name="lease-base-rent"
                  id="lease-base-rent"
                  autocomplete="cc-lease-base-rent"
                  @input="baseRentChanged = true"
                /> -->
              </hb-form>
              <hb-form
                v-if="form.show_security_deposit"
                label="Security Deposit"
                :editable="!unitComponentStatusFlag" :active="'Security Deposit'" :hide-actions="true"
              >
              <template v-slot:display >
              <hb-icon mdi-code="mdi-currency-usd" color="#C4CDD5" ></hb-icon>
                 {{ form.security_deposit }}
              </template>
              <template v-slot:edit>
                <HbTextField
                  v-model.lazy="form.security_deposit"
                  v-validate="'decimal:2|min_value:0'"
                  placeholder="Enter Security Deposit"
                  prepend-inner-icon="mdi-currency-usd"
                  data-vv-scope="info"
                  data-vv-name="security"
                  data-vv-as="Security Deposit"
                  :error="errors.collect('info.security').length > 0"
                  name="lease-security-deposit"
                  id="lease-security-deposit"
                  autocomplete="cc-lease-security-deposit"
                  
                />
              </template>
              </hb-form>


    
              <hb-form
                v-if="isPaymentCycleAllowed && payment_cycle_options && payment_cycle_options.length"
                label="Payment Cycle"
                required
                :editable="!unitComponentStatusFlag" :active="'Payment Cycle'" :hide-actions="true"
              > 
             <template v-slot:display>
              {{ form.payment_cycle }}
             </template>
             <template v-slot:edit>
              <hb-select  
                  :items="payment_cycle_options" 
                  v-model="form.payment_cycle"
                  item-text="label"
                  item-value="label"
                  :clearable="false"
                  placeholder="Choose Payment Cycle"
                  @change="setPaymentCycle"
              ></hb-select> 
             </template>

        
              </hb-form>

              <hb-form
                v-if="isBillingCycleAllowed && !payment_cycle_options?.length && unit_template?.BillingCycleOptions?.length"
                label="Billing Cycle"
                :editable="!unitComponentStatusFlag" :active="'Billing Cycle'" :hide-actions="true"
              > 
                <template v-slot:display>
                  {{ getCurrentBillingCycle }}
                </template>
                <template v-slot:edit>
                  <hb-select  
                    :items="unit_template?.BillingCycleOptions" 
                    v-model="form.billing_cycle_id"
                    id="billing_cycle"
                    name="billing_cycle"
                    item-value="billing_cycle_id"
                    hide-details
                    :clearable='true'
                    placeholder="Select New Billing Cycle"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.value | formatMonth }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.value | formatMonth }}
                    </template>
                  </hb-select> 
                </template>
              </hb-form>

              <hb-form label="Discount" v-if="!form.payment_cycle || form.payment_cycle.toLowerCase() === 'monthly' || hasPermission('payment_cycle_discounts')"
              :editable="!unitComponentStatusFlag " :active="'Discount'" :hide-actions="true" :edit="true"
              >
              <template v-slot:display>
              {{ discounts.length && form?.discount_id ? discounts.find(item => item.id == form.discount_id)?.name : unitComponentStatusFlag ? "No Discount Selected": "Click to Select a Discount" }}
              </template>
              <template v-slot:edit>
                <hb-select
                  v-model="form.discount_id"
                  :items="discounts"
                  item-text="name"
                  item-value="id"
                  placeholder="Select Discount"
                >
                  <template v-slot:item="data" >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="hb-font-body-medium hb-text-night">{{data.item.name}}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span class="hb-font-caption hb-text-night-light">{{data.item.description}}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </hb-select>
              </template>
              </hb-form>

              <hb-form label="Promotion" v-if="!form.billing_cycle_id && (!form.payment_cycle || form.payment_cycle.toLowerCase() === 'monthly' || hasPermission('payment_cycle_promotions'))"
              :editable="!unitComponentStatusFlag" :active="'Promotion'" :hide-actions="true" :start-open="!unitComponentStatusFlag"
              >
              <template v-slot:display>
                <v-row v-for="(promo, i) in form.promotions" :key="i" no-gutters>
                  <v-col :cols="promotionFound ? 11 : 12" class="ma-0 pa-0">
                  {{form.promotions[i].Promotion.id ? form.promotions[i].Promotion?.name : unitComponentStatusFlag ? "No Promotions Selected" : "Click to Select Promotions"}}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:edit>
                <v-row v-for="(promo, i) in form.promotions" :key="i" no-gutters>
                  <v-col :cols="promotionFound ? 11 : 12" class="ma-0 pa-0">
                    <hb-select
                      v-model="form.promotions[i].Promotion"
                      :items="promos(i)"
                      :clearable="false"
                      return-object
                      item-text="name"
                      item-value="id"
                      placeholder="Select Promotion"
                      single-line
                      @change="handlePromoChange()"
                      background-color="white"
                    >
                      <template v-slot:item="data" >
                        <v-list-item-content>
                          <v-list-item-title>
                            <span class="hb-font-body-medium hb-text-night">{{data.item.name}}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="hb-font-caption hb-text-night-light">{{data.item.description}}</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </hb-select>
                  </v-col>
                  <v-col cols="1" align="right" v-if="promotionFound">
                    <hb-icon @click="removePromo(i)" class="hand">mdi-close</hb-icon>
                  </v-col>
                </v-row>
                <div v-if="!allowNewPromotion">
                  <hb-link @click="addPromo">+ Add Promotion</hb-link>
                </div>
                  
              </template>
              </hb-form>

              <hb-form
                label="AutoPay"
                required
                full
                :divider="false"
                :editable="!unitComponentStatusFlag" :active="'AutoPay'" :hide-actions="true"
              >
              <template v-slot:display>
              <v-row>
                <v-col >AutoPay will run
                {{ form.auto_pay_after_billing_date }}
                 days after the billing date. </v-col>
              </v-row>

              </template>

              <template v-slot:edit>
                <v-row no-gutters>
                  <v-col cols="4" class="ma-0 pa-0 hb-text-night" style="max-width: 8em;">AutoPay will run</v-col>
                  <v-col class="mx-0 mb-0 pa-0" cols="4" style="max-width: 18em; margin-top:1px;">
                  <span @click="showNotificationModal('edit_autopay')">
                    <hb-select
                      v-model="form.auto_pay_after_billing_date"
                      v-validate="'required'"
                      :items="autopayDays"
                      attach
                      placeholder="Select"
                      id="auto_pay_after_billing_date"
                      name="auto_pay_after_billing_date"
                      data-vv-as="AutoPay Run Day"
                      data-vv-scope="info"
                      :error="errors.collect('info.auto_pay_after_billing_date').length > 0"
                      class="px-3"
                      :disabled="!hasPermission('edit_autopay_in_movein_flow')"
                      :clearable="false"
                    />
                    </span>
                      <template v-slot:selection="data">
                        <div style="margin-top:-8px;">
                          <span v-if="data.item == 1">{{data.item}} - Delinquent </span>
                          <span v-else>{{data.item}}</span>
                        </div>
                      </template>
                      <template v-slot:item="data">
                        <span v-if="data.item == 1">{{data.item}} - Delinquent </span>
                        <span v-else>{{data.item}}</span>
                      </template>
                  </v-col>
                  <v-col class="ma-0 pa-0 hb-text-night"> days after the billing date. </v-col>
                </v-row>
              </template> 
              </hb-form>
              
            </template>
          </hb-expansion-panel>
        </v-expansion-panels>

      </div>
    </div>
    <!-- <div class="new-tenant-walkthrough-footer">
      <hb-bottom-action-bar
        @close="$emit('close')"
        :top-border="false"
      >
      <template v-slot:left-actions>
          <help-juice />
        </template>
        <template v-slot:right-actions>
          <hb-btn
            v-show="contact.id"
            color="secondary"
            :disabled="isLoading($options.name)"
            @click="$emit('retire_lead')"
          >
            Retire Lead
          </hb-btn>
          <hb-btn
              color="secondary"
              :disabled="isLoading($options.name)"
              @click="showLinkReservationDialog = true"
            >
              Send Reservation Link
              <CommonDialog :showLinkSenderDialog="showLinkReservationDialog" :title="'Confirm'" :content="'How do you want to send the online reservation link?'" :linkType="'reservation'" @closeLinkSender="showLinkReservationDialog = false" ></CommonDialog>  
          </hb-btn>
          <hb-btn
              color="secondary"
              :disabled="isLoading($options.name)"
              @click="showLinkRentalDialog = true"
            >
              Send Rental Link
              <CommonDialog :showLinkSenderDialog="showLinkRentalDialog" :title="'Confirm'" :content="'How do you want to send the online rental link?'" :linkType="'rental'" @closeLinkSender="showLinkRentalDialog = false" ></CommonDialog>  
          </hb-btn>
          <hb-btn
            v-if="lease.status !== 2"
            :disabled="!configuration_unit.id || !contact.id || isLoading($options.name) || savingInProgress || errorOut"
            @click="reserve"
          >
            Reserve
          </hb-btn>

          <hb-btn
            :disabled="!configuration_unit.id || !contact.id || isLoading($options.name) || savingInProgress || errorOut"
            :loading="savingInProgress"
            @click="saveLease"
          >
            Move In
          </hb-btn>
        </template>
      </hb-bottom-action-bar>

    </div> -->

    <hb-modal v-model="updateReservationConfirmation" v-if="updateReservationConfirmation" size="medium" title="Update Reservation" show-help-link>
        <template v-slot:content>
          <div class="pa-6">
            This action will update existing reservation click <b>Confirm</b> to proceed with update or <b>Cancel</b> to abort the operation
          </div>
        </template>
        <template v-slot:right-actions>
            <hb-btn color="primary" @click="confirmReservation" >Confirm</hb-btn>
        </template>
      </hb-modal>
    <hb-modal v-model="make_reservation" v-if="make_reservation" size="medium" title="Reserve This Space" show-help-link>
        <template v-slot:content>
          <div class="pa-6">
            Click to save as a reservation.
          </div>
        </template>
        <template v-slot:right-actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="confirmReservation" >Make Reservation</hb-btn>
        </template>
      </hb-modal>
      <payments-notification-modal
	  v-model="notificationModal"
	  :notificationType="notificationModalType"
	/>
  </div>
</template>




<script type="text/babel">
    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import moment from 'moment';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import { mapGetters, mapActions } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
    import LEASE from '@/constants/lease.js'; 
    import LEASE_CONFIGURATION from '@/constants/lease_configuration.js'; 
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';
    import CommonDialog from './commonDialog.vue';

    export default {
        name: 'LeaseInfo',
        mixins: [notificationMixin],
        components: {
            HbDatePicker,
            Status,
            PaymentsNotificationModal,
            CommonDialog,
        },
        props:['promotion_na','isNational','loading','unitComponentStatusFlag','isPromoFieldDirty', 'apwPlans', 'contact_na'],
        data: () => ({
          active:"",
          payment_cycle_options:[],
            start_date_menu: false,
            end_date_menu: false,
            showLinkRentalDialog:false,
            showLinkReservationDialog:false,
            panel: 0,
            leaseTypes:[
                'Month to Month'
            ],
            make_reservation: false,
            form: {
                id: null,
                unit_id: '',
                rent: 0,
                security_deposit: 0,
                show_security_deposit: false,
                type: '',
                start_date: '',
                end_date: '',
                bill_day: 1,
                discount_id: {},
                payment_cycle: 'Monthly', 
                auto_pay_after_billing_date: 0,
                promotions: [],
                notes: '',
                status: '',
                decline_insurance: false,
                baseRentChanged: false,
                send_invoice: true    // email monthly invoice
            },
            loaded: false,
            savingInProgress: false,
            errorOut:false,
            notificationModal: false,
            notificationModalType: '',
            minDate:'',
            showAPWDates:false,
            updateReservationConfirmation:false,
            apwEndDate:'',
            inStoreTime:'',
            APWInfo:true,
            cutOffTimeInfo:true,
        }),

        computed:{
            autopayDays() {
              let days = Array(31)
                .fill(0)
                .map((n, i) => i );
              return days;
            },
            allowNewPromotion() {
              const isFixedConsecutivePromotionSelected = this.form.promotions.some(promotion => promotion.Promotion.type == 'fixed' &&
               promotion.Promotion.consecutive_pay);

              return isFixedConsecutivePromotionSelected;
            },

            computedDateFormattedMomentjs () {
                return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : ''
            },

            isFixedLength(){
                return this.form.type === 'Fixed Length';
            },
            ...mapGetters({
                properties:           'propertiesStore/filtered',
                property:             'onBoardingStore/property',
                unit:                 'onBoardingStore/unit',
                reservation:          'onBoardingStore/reservation',
                // discounts:           'onBoardingStore/discounts',
                // promotions:           'onBoardingStore/promotions',
                activePromotions:     "revManStore/getActivePromotions",
                propertyDiscounts:    "revManStore/getPropertyDiscounts",
                // payment_cycles:       'onBoardingStore/payment_cycles',
                lease:                'onBoardingStore/lease',
                configuration_unit:  'onBoardingStore/configuration_unit',
                configuration_lease:  'onBoardingStore/configuration_lease',
                contact:              'onBoardingStore/contact',
                tenants:              'onBoardingStore/tenants',
                insurance:            'onBoardingStore/insurance',
                services:            'onBoardingStore/services',
                lead:                'onBoardingStore/lead',
                template:             'onBoardingStore/template',
                hasPermission:       'authenticationStore/rolePermission',
                configuration_invoice:  'onBoardingStore/configuration_invoices',
                currentBillingProfile: 'billingProfileStore/currentBillingProfile',
                isPaymentCycleAllowed: 'propertiesStore/isPaymentCycleAllowed',
                isBillingCycleAllowed: 'propertiesStore/isBillingCycleAllowed',
                getFollowUpStatus:     'onBoardingStore/getFollowUpStatus',
            }),
            unit_template(){
              return this.template[this.configuration_unit.id];
            },
            discounts(){
              if(this.isNational) return [this.promotion_na];
              return this.propertyDiscounts;
            },
            promotion: {
                get() { return this.$store.getters['onBoardingStore/promotion'] } ,
                set(val){  return this.$store.commit('onBoardingStore/setPromotion', val ) }
            },
            daysOfMonth(){
                var days = [];
                var v;
                var s= ["th","st","nd","rd"];
                for(var i = 1; i <= 31; i++){
                    v=i%100;
                    var label = i + (s[(v-20)%10]||s[v]||s[0]);

                    if(i.toString() === moment(this.$store.getters.leaseStartDate).format('D')){
                        label += ' (Anniversary)'
                    }
                    days.push({
                        value: i,
                        text: label
                    });
                }
                return days;
            },
            isPermissionToEdit() {
              return this.hasPermission('edit_move_in_rate');
            },

            computedPropertyInfo() {
              if(this.property && this.property.number && this.configuration_unit && this.configuration_unit.Address){
                this.getCutOffTimeStatus(); // await not added as it will cause issue with address format 
                return this.property.number + ' - ' + this.$options.filters.formatAddress(this.configuration_unit.Address, '^', true);
              } else {
                return undefined;
              }
            },
            isPermissionToEditBillingDate() {
              return this.hasPermission('edit_bill_day');
            },
            getCurrentBillingCycle() {
              let billing_cycle = this.unit_template?.BillingCycleOptions.find(bco => bco.billing_cycle_id === this.form?.billing_cycle_id);
              return billing_cycle?.value ? this.$options.filters.formatMonth(billing_cycle?.value) : 'Select New Billing Cycle';
            },
            setMinDateForBackDateMoveIn(){
              let min_date = new Date().toISOString().substring(0, 10)
              if((this.payment_cycle_options && this.payment_cycle_options.length) || this.isBillingCycleAllowed){
                this.form.start_date = moment().format('YYYY-MM-DD')            
                return min_date;
              } else {
                return this.minDate ? this.minDate : ''
              }
            }
        },
        beforeMount() {
          this.startLoading(this.$options.name);
        },
        async mounted(){
            if(!this.configuration_unit || !this.configuration_unit.id){
                this.errorSet(this.$options.name, "No space provided");
            }

            //delete lease if initial unit is changed after getting back to space step
            if(this.lease.id && this.lease.status === 2){
              await this.deleteInitialLease();
            }

            // await this.$store.dispatch('onBoardingStore/getPromotions', this.configuration_unit);
            await this.$store.dispatch('revManStore/fetchActivePromotions', { contact_id: this.contact?.id || null, property_id: this.configuration_unit?.property_id || null, unit_id: this.configuration_unit?.id || null, channel_type: "promo-plan", rental_flow: true });
            await this.$store.dispatch('revManStore/fetchPropertyDiscounts', { property_id: this.configuration_unit?.property_id || null });

            // new unit
            
            if(!this.lease.unit_id){
                await this.$store.commit("onBoardingStore/setTaxExempt", false);
                await this.$store.commit("onBoardingStore/setTaxExemptDescription", null);
                await this.$store.dispatch('onBoardingStore/buildDefaultLease',  {configuration_unit:this.configuration_unit,isNational:this.isNational});
                await this.$store.dispatch('onBoardingStore/getEstimate', { config:true, unit: this.configuration_unit,isNational:this.isNational, contactNA : this.contact_na});
            } else if(this.lease.unit_id === this.configuration_unit.id){
                // same unit as configured
              // If unit equals the unit on the lease
              
                await this.$store.dispatch('onBoardingStore/getTemplate');
                await this.$store.dispatch('onBoardingStore/copyLeaseFields');
                await this.$store.dispatch('onBoardingStore/getServices');
            } else {
              // else build default lease
                // different unit than on lease
              await this.$store.dispatch('onBoardingStore/copyLeaseFields');
              await this.$store.dispatch('onBoardingStore/buildDefaultLease',  {configuration_unit:this.configuration_unit,isNational:this.isNational});

            }


           // await this.$store.dispatch('onBoardingStore/getEstimate', { config:true, unit: this.configuration_unit});

            this.form = JSON.parse(JSON.stringify(this.configuration_lease));

            console.log("this.configuration_lease", this.configuration_lease)

            
            this.form.start_date = moment().format('YYYY-MM-DD')            
            this.updateBillDay(this.form.start_date)      
            

            let  promotions_arr = [];
            let discounts_arr = [];
            this.form.promotions = [];
            this.form.discount_id = '';
            if(this.reservation.Setup.Discounts && this.reservation.Setup.Discounts.length){
              promotions_arr = this.reservation.Setup.Discounts.filter(p => p.Promotion.label === "promotion");
              discounts_arr = this.reservation.Setup.Discounts.filter(p => p.Promotion.label === "discount");
            }
            if(!this.reservation.Setup.Discounts && this.lease && this.lease.id)
            {
              let lease_discounts = await api.get(this, api.LEASES + this.lease.id + '/discounts', {date: moment(this.lease.start_date).format('YYYY-MM-DD') });

              if(lease_discounts.discounts && lease_discounts.discounts.length){
                promotions_arr = lease_discounts.discounts.filter(p => p.Promotion.label === "promotion");
                discounts_arr = lease_discounts.discounts.filter(p => p.Promotion.label === "discount");
              }
            }
            this.form.promotions = promotions_arr.length ? promotions_arr : [];
            
            if(this.isNational){
              this.form.discount_id = this.promotion_na.id
            }else{
              this.form.discount_id = discounts_arr.length ? discounts_arr[0].Promotion.id : '';
            }
            
            if(this.unit_template?.payment_cycle_options.length){
              this.payment_cycle_options = [].concat([{
                label: 'Monthly'
              }], this.unit_template.payment_cycle_options); 
              this.form.payment_cycle = this.form.payment_cycle || 'Monthly';
              this.minDate = new Date().toISOString().substring(0, 10) 
            }
            let form = JSON.parse(JSON.stringify(this.form));
           
            this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...form });
            if(this.$store.reservation && this.$store.reservation.Lease && this.$store.reservation.Lease.unit_id === this.unit_id ){
                //      await this.$store.dispatch('findReservation', this.$store.reservation);
            } else {
                // await this.fetchData();
            }
            // if(!this.form.promotions.length){
            //   if(!this.isPromoFieldDirty && this.configuration_unit.promotion_id){
            //     this.addPromo(this.configuration_unit.promotion_id);
            //   }
            // }
            
            this.setLeasePeriodType();
            this.$emit('update:loading', false);
            this.stopLoading(this.$options.name);
            EventBus.$emit("leaseInfoComponentMounted") // redundant can be removed
            this.$store.dispatch('onBoardingStore/setAutoPayRequiredForRental', { selected_promotions: this.form.promotions, available_promotions: this.activePromotions });
            this.setApwFlag()
        },
        async created(){
        EventBus.$on('contactDetailsUpdatedDuringRental', async () => {
            await this.$store.dispatch('revManStore/fetchActivePromotions', { contact_id: this.contact?.id || null, property_id: this.configuration_unit?.property_id || null, unit_id: this.configuration_unit?.id || null, channel_type: "promo-plan", rental_flow: true });
            const toRemove = [];
            this.form.promotions.forEach((p, index) => {
            const isInActivePromotions = this.activePromotions.some(activeP => activeP.id === p.Promotion.id);
              if (!isInActivePromotions) {
                toRemove.push(index);
              }
            });

            for (let i = toRemove.length - 1; i >= 0; i--) {
              this.form.promotions.splice(toRemove[i], 1);
            }
              
            if(!this.form.promotions.length){
              if(!this.isPromoFieldDirty && this.unit.promotion_id){
                this.addPromo(this.unit.promotion_id);
              }
            }
            this.$store.dispatch('onBoardingStore/setAutoPayRequiredForRental', { selected_promotions: this.form.promotions, available_promotions: this.activePromotions });
        });
        },
        methods:{
          formatValue(name, type, value) {
      let formattedValue = type === 'dollar' ? `$${value}` 
                         : type === 'fixed' ? `$${value}` 
                         : type === 'percent' ? `${value}%` 
                         : value;
      return `${name}-${formattedValue}`;
    },
          promotionFound() {
            if(this.form.promotions.length && this.form.promotions[0].Promotion && this.form.promotions[0].Promotion.id.length) {
              return true;
            }
            return false;
          },
          handlePromoChange(){
            this.$store.dispatch('onBoardingStore/setAutoPayRequiredForRental', { selected_promotions: this.form.promotions, available_promotions: this.activePromotions });
          },
          setLeasePeriodType() { 
            const { TYPES } = LEASE;
            if(this.form.end_date) {
              this.form.type = TYPES.fixed_length.value;
              return;
            }

            this.form.type = TYPES.month_to_month.value;
          },
          //delete and reset lease if lease unit and configuration unit are not same
          async deleteInitialLease() {
            console.log("================================================================================================ deleteInitialLease fnk called",this.lease.unit_id, this.configuration_unit.id, this.lease.unit_id, this.configuration_unit.id),this.lease.unit_id && this.configuration_unit.id && this.lease.unit_id != this.configuration_unit.id;
            if(this.lease.unit_id && this.configuration_unit.id && this.lease.unit_id != this.configuration_unit.id){
              await this.$store.dispatch('onBoardingStore/deleteLease', this.lease);
            }
          },
            // MOVED these two methods from computed to methods because they have side effects.  They dont seem to be used.
            async leaseType(){
                if(!this.isFixedLength && !this.baseRentChanged) {
                    this.form.end_date = null;
                    let status = await this.$validator.validateAll("info");
                    if (!status) {
                        this.baseRentChanged = false;
                        return;
                    }
                }
                this.baseRentChanged = false;
                return this.form.type;
            },
            async endDate() {
                if(this.isFixedLength && !this.baseRentChanged) {
                    let status = await this.$validator.validateAll("info");
                    if (!status) {
                        this.baseRentChanged = false;
                        return;
                    }
                }
                this.baseRentChanged = false;
                return this.form.end_date;
            },
          promos(index){
            const allPromotions = this.activePromotions.filter(promo => promo.label === 'promotion');
            const selectedPromotions = this.form.promotions;
            const selectedPromotionsIds = selectedPromotions.map(sp => sp.Promotion.id);
            // If user has already selected some promotion don't display it in new promotion dropdown
            return allPromotions.filter(p => !selectedPromotionsIds.includes(p.id) || this.form.promotions[index].Promotion.id == p.id);
          },
          removePromo(index){
              let form = JSON.parse(JSON.stringify(this.form));
              form.promotions.splice(index, 1)
              this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...form });
              this.form = JSON.parse(JSON.stringify(form));
              this.$store.dispatch('onBoardingStore/setAutoPayRequiredForRental', { selected_promotions: this.form.promotions, available_promotions: this.activePromotions });
          },
          addPromo(promoId){
            let form = JSON.parse(JSON.stringify(this.form));
            form.promotions.push({
              Promotion: {
                id: promoId ?? ''
              }
            })
            this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...form });
            this.form = JSON.parse(JSON.stringify(form));
          },

            setPaymentCycle(){
              if(!this.hasPermission('payment_cycle_discounts')){
                this.form.discount_id = null;
              }

               if(!this.hasPermission('payment_cycle_promotions')){
                this.form.promotions = [];
              }
              
              let form = JSON.parse(JSON.stringify(this.form));




                switch(form.payment_cycle){
                  case 'Monthly': 
                  form.promotions.push({
                      Promotion: {
                        id: ''
                      }
                    })
                  default:
                    this.calculateBilledMonths();
                }
                this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...form });
                this.form = JSON.parse(JSON.stringify(form));
              

              // 
            },
            // async getLeaseSetUp(){
            //     if(!this.lease_id){
            //         await this.$store.dispatch('onBoardingStore/buildDefaultLease', {
            //             start_date: this.form.start_date
            //         });
            //     }
            // },
            async updateReservation(){
            // validate reservation form 
            let status = await this.$validator.validateAll("info");
                
                if (!status) {
                  this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                  this.$emit('update:loading', false);
                  this.stopLoading(this.$options.name);
                  return "RESERVE_VALIDATION_ERROR";
                }
                if(this.apwPlans?.reservationPlan?.dates && !this.isDateInRange(this.apwPlans?.reservationPlan?.plan?.days, this.form?.start_date)) {
                this.showMessageNotification({ type: 'error', description: `The Move-In Date you have selected cannot be completed because it is outside of the given rental/reservation window. Please see suggested Move-In Dates below: ${this.apwPlans?.reservationPlan?.dates}` });
                return "RESERVE_VALIDATION_ERROR";
              }
            // updated reservation flow,
            this.updateReservationConfirmation = true
            // Payload for delete reservation:
            // let error = await this.confirmReservation()
            // return error

            // 1. delete the previous reservation and create a new with the changes.
            },

            async confirmReservation(){

              if(this.showAPWDates){
                this.form.end_date = this.apwPlans?.reservationPlan?.plan?.days > this.apwPlans?.rentalPlan?.plan?.days ? moment().add(this.apwPlans?.reservationPlan?.plan?.days, 'days').format('YYYY-MM-DD') : moment().add(this.apwPlans?.rentalPlan?.plan?.days, 'days').format('YYYY-MM-DD');
              }
                // if(this.reservation.id){
                // }
                // if(this.lease.unit_id === this.unit.id){
                //     // CONVERT RESERVATION
                //     await this.$store.dispatch('onBoardingStore/convertReservation', lease);
                //     await this.$store.dispatch('onBoardingStore/getTenants');
                //     //lease.id = this.lease.id;
                //     await this.$store.commit('onBoardingStore/setLease', lease);
                // } else {
                //     // DELETE Reservation, Save New Pending
                //     await this.$store.dispatch('onBoardingStore/deleteReservation');
                //     lease.unit_id = this.unit.id;
                //
                //     lease = await this.$store.dispatch('onBoardingStore/saveLease', lease);
                //     await this.$store.dispatch('onBoardingStore/saveTenants', {
                //         lease_id: lease.id
                //     });
                //     await this.$store.dispatch('onBoardingStore/getTenants');
                // }
                this.startLoading(this.$options.name);

                if(!this.contact.id && !this.tenants.length) {
                    this.errorSet(this.$options.name, "An error has occurred, there is no one on this reservation.");
                    return;
                }
                let contacts = [];
                if(this.reservation.id){
                    // promotions_a = this.form.promotions.filter(p => p.Promotion).map(a => ({ promotion_id: a.id }));
                    contacts = [{ id: this.contact.id }]
                } else {
                    contacts = [{ id: this.contact.id }]
                }
                let lease = JSON.parse(JSON.stringify(this.form));
                const lease_start_date = moment(lease.start_date);
                var reservation_time = moment()
                reservation_time.date(lease_start_date.date())
                reservation_time.month(lease_start_date.month())
                reservation_time.year(lease_start_date.year())
                reservation_time = moment(reservation_time).utc().format('YYYY-MM-DD HH:mm:ss')

                let discounts;
                if(lease.discount_id){
                  let filteredDiscounts = this.discounts.filter((item) => {
                    return item.id == lease?.discount_id 
                  })
                  discounts = {
                    discount_id: filteredDiscounts[0]?.id,
                    name: filteredDiscounts[0]?.name
                  }
                }
                var save = {
                    start_date: moment(lease.start_date).format('YYYY-MM-DD'),
                    promotions: lease.promotions.map(p => ({ promotion_id: p.Promotion?.id, name : p.Promotion?.name})),
                    contacts: contacts,
                    comments: lease.notes,
                    bill_day: lease?.bill_day,
                    rent: lease.rent,
                    security_deposit: lease.security_deposit || 0,
                    discount_id: lease.discount_id,
                    auto_pay_after_billing_date: lease.auto_pay_after_billing_date,
                    time:  reservation_time,
                    source: this.lead.source,
                    length_of_stay: this.lead.length_of_stay,
                    content: this.lead.content,
                    move_in_date: moment(lease.start_date).format('YYYY-MM-DD'),
                    rent_as_business: this.contact.rent_as_business,
                    configuration_invoice: this.configuration_invoice,
                    discount: discounts,
                    billing_cycle_id: this.form.billing_cycle_id,
                    follow_up:this.getFollowUpStatus,
                    lead_id: this.lead.id,
                }
                save.promotions = save.promotions.filter(p => p.promotion_id !== "");

                if(lease.type.toLowerCase() === "fixed length") {
                  save.end_date = (lease.end_date) ? moment(lease.end_date).clone().startOf('day').format('YYYY-MM-DD') : null;
                }

                // console.log(" --DEBUG-- ");
                // console.log(this.lease.id);
                // console.log(this.lease.status );
                // console.log(this.reservation.id)
                // console.log(this.lease.unit_id)
                // console.log(this.configuration_unit.id)
                if (this.lease.id && this.lease.status === 0 && this.reservation.id){

                    if(this.lease.unit_id === this.configuration_unit.id){
                        // same unit, update reservation
                        await api.put(this, api.UNITS + this.configuration_unit.id + '/reserve/' + this.reservation.id, save);

                    } else {
                        // delete reservation and save new
                        await this.$store.dispatch('onBoardingStore/deleteReservation');
                        lease.unit_id = this.configuration_unit.id;
                        await api.post(this, api.UNITS + this.configuration_unit.id + '/reserve', save);
                    }
                } else {
                    await api.post(this, api.UNITS + this.configuration_unit.id + '/reserve', save);
                }
                let reservationSuccessMessage = {
                  0:`Your Reservation for Move in day ${moment(this.form.start_date, 'YYYY-MM-DD').format('MMMM D, YYYY')} is confirmed.`,
                  1:`Reservation Successful`
                }
                this.showMessageNotification({ type: 'success', description: this.showAPWDates ? reservationSuccessMessage['0'] : reservationSuccessMessage['1'] });
                
                EventBus.$emit('lead_saved');
                EventBus.$emit('reservation_made');
                this.make_reservation = false;
                var newReservationData = {
                  contact_id: this.contact.id,
                  unit_id: this.configuration_unit.id
                }
                this.$emit('close_then_go_to_reservation', newReservationData);
                this.stopLoading(this.$options.name);

            },
            async reserve(){
              // console.log("=================================================== is dateRange",this.isDateInRange(this.apwPlans.reservationPlan?.plan?.days, this.form?.start_date));
              if(this.apwPlans?.reservationPlan?.dates && !this.isDateInRange(this.apwPlans?.reservationPlan?.plan?.days, this.form?.start_date, 'RESERVATION')) {
                let reservation_msg;
                if(this.apwPlans?.reservationPlan?.plan?.days == 0){
                  reservation_msg = `Reservation is not allowed for selected Unit`
                }
                else{
                  reservation_msg = `The selected move-in date falls outside the rent/reservation window. Please choose a move-in date between ${this.apwPlans?.reservationPlan?.dates}`
                }
                this.showMessageNotification({ type: 'error', description: ' ', note: reservation_msg});
                return "RESERVE_VALIDATION_ERROR";
              }

                this.$emit('update:loading', true);
                this.startLoading(this.$options.name);
                let status = await this.$validator.validateAll("info");
                
                if (!status) {
                  this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                  this.$emit('update:loading', false);
                  this.stopLoading(this.$options.name);
                  return "RESERVE_VALIDATION_ERROR";
                }

                this.make_reservation = true;
                this.$emit('update:loading', false);
                this.stopLoading(this.$options.name);
            },
            isDateInRange(days, dateString, type) {
                const inputDate = new Date(dateString);

                // Check if the input date is valid
                if (isNaN(inputDate.getTime())) {
                    throw new Error('Invalid date format. Please use yyyy-mm-dd.');
                }

                // Get the current date and reset time to midnight
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);

                // Set the end date to the current date plus the number of days
                const endDate = new Date(currentDate);
                endDate.setDate(currentDate.getDate() + +days); // Using unary + for conversion

                // Reset time components for input date
                inputDate.setHours(0, 0, 0, 0);

                // Check if the input date is within the range
                
                return (type == "RESERVATION" && days == 0) ? false : (inputDate >= currentDate && inputDate <= endDate);
            },
            async saveLease(){
              if(this.apwPlans?.rentalPlan?.dates && !this.isDateInRange(this.apwPlans?.rentalPlan?.plan?.days, this.form?.start_date, 'MOVE_IN')) {
                let rentalMessage = ''
                if(this.apwPlans?.rentalPlan?.plan?.days == 0){
                  rentalMessage = `Advanced rental is not allowed for selected Unit`
                }else{
                  rentalMessage = `The selected move-in date is not allowed. Please choose a move-in date within the reservation/rental window for today.`
                }
                this.showMessageNotification({ type: 'error', description: ' ', note: rentalMessage });
                return "LEASE_FORM_ERROR";
              }
                this.$emit('update:loading', true);
                this.startLoading(this.$options.name);
                let status = await this.$validator.validateAll("info");

                if (!status) {
                  this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                  this.$emit('update:loading', false);
                  this.stopLoading(this.$options.name);
                  return "LEASE_FORM_ERROR";
                }

                this.savingInProgress = true;

                /*
                  IF pending
                    IF Same unit number,
                      UPDATE
                    ELSE
                      Delete Pending, re-save new pending
                  IF Reservation
                    IF Same unit number,
                        Save as Pending
                      ELSE
                        Delete Reservation,
                        Save new pending
                  ELSE
                    Save New Lead
                    Save AS Tenant
                   */

                if(!this.contact.id && !this.tenants.length) {
                  this.errorSet(this.$options.name, "An error has occurred, there are no tenants set on this lease");
                    this.$emit('update:loading', false);
                    this.stopLoading(this.$options.name);
                    this.savingInProgress = false;
                  return "LEASE_FORM_ERROR";
                }
                let f = JSON.parse(JSON.stringify(this.form));
                
                let lease = {
                    bill_day: this.form?.bill_day,
                    decline_insurance: this.form.decline_insurance,
                    notes: this.form.notes,
                    discount_id: this.form.discount_id,
                    auto_pay_after_billing_date: this.form.auto_pay_after_billing_date,
                    promotions: f.promotions.map(p => ({ promotion_id: p.Promotion.id })),
                    payment_cycle: f.payment_cycle,
                    rent: this.form.rent,
                    security_deposit: this.form.security_deposit,
                    unit_id: this.configuration_unit.id,
                    start_date: this.form.start_date,
                    end_date: this.form.end_date,
                    send_invoice: this.form.send_invoice,
                    invoice_send_day: this.form.invoice_send_day,
                    billing_cycle_id: this.form.billing_cycle_id,
                    PaymentCycleOptions: this.payment_cycle_options || []
                } 
                if(this.isNational) {
                  lease.national_accounts = true;
                  lease.contact_id = this.contact.id;
                }

                lease.promotions = lease.promotions.filter(p => p.promotion_id !== "");

                lease.start_date = moment(lease.start_date).clone().startOf('day').format('YYYY-MM-DD');
                if(this.isFixedLength) {
                  lease.end_date = (lease.end_date) ? moment(lease.end_date).clone().startOf('day').format('YYYY-MM-DD') : null;
                }

                let products = this.services.filter(s=> s.Product.default_type === 'product' || s.Product.default_type === 'late').map(s => {
                  return {
                    product_id: s.Product.id,
                    qty: s.qty
                  }
                });
                lease.products = products;
                lease.insurance_id = this.insurance.insurance_id;

                /* IF IS PENDING */

                //return;
                if(this.lease.id && this.lease.status === 2){
                  lease.id = this.lease.id;
                  lease.status = this.lease.status;

                  if(this.lease.unit_id && this.lease.unit_id === lease.unit_id){
                      // UPDATE PENDING
                     lease = await this.$store.dispatch('onBoardingStore/saveLease', lease);
                  } else {
                    //used deleteInitialLease method to resolve lease null issue after deleting here
                      //await this.$store.dispatch('onBoardingStore/deleteLease', this.lease);
                      //lease.unit_id = this.configuration_unit.id;
                      // DELETE PENDING, Save New
                  } 

                  await this.$store.dispatch('onBoardingStore/getTenants');

                  if(!this.tenants.find(x=> x.contact_id === this.contact.id)) {
                    await this.$store.dispatch('onBoardingStore/saveTenants', { lease_id: lease?.id, lead_id: this.lead?.id });
                    await this.$store.dispatch('onBoardingStore/getTenants');
                  }

                } else if (this.lease.id && this.lease.status === 0 && this.reservation.id) {
                    lease.id = this.lease.id;
                    lease.reservation_id = this.reservation.id || null;

                    if(this.lease.unit_id === this.configuration_unit.id){
                        // CONVERT RESERVATION
                        await this.$store.dispatch('onBoardingStore/convertReservation', lease);
                        await this.$store.dispatch('onBoardingStore/getTenants');
                        //lease.id = this.lease.id;
                        //await this.$store.commit('onBoardingStore/setLease', lease);
                    } else {
                        // DELETE Reservation, Save New Pending
                        await this.$store.dispatch('onBoardingStore/deleteReservation');
                        lease.unit_id = this.configuration_unit.id;
                        
                        lease = await this.$store.dispatch('onBoardingStore/saveLease', lease);
                        await this.$store.dispatch('onBoardingStore/saveTenants', {
                            lease_id: lease?.id,
                        lead_id: this.lead?.id

                        });
                        await this.$store.dispatch('onBoardingStore/getTenants');
                    }
                } else {
                    // Save New Pending
                    lease.unit_id = this.configuration_unit.id;

                    try {
                        lease = await this.$store.dispatch('onBoardingStore/saveLease', lease);
                    } catch(err){
                        this.errorSet(this.$options.name, err);
                        this.$emit('update:loading', false);
                        this.stopLoading(this.$options.name);
                        this.savingInProgress = false;
                        return "LEASE_FORM_ERROR";
                    }
                    // add tenants to lease
                    await this.$store.dispatch('onBoardingStore/saveTenants', {
                        lease_id: lease?.id,
                        lead_id: this.lead?.id
                    });
                    await this.$store.dispatch('onBoardingStore/getTenants');
                }

                await this.$store.dispatch('onBoardingStore/getUnitInfo', {unit_id: this.configuration_unit.id});
                this.$emit('saved', lease);
                this.savingInProgress = false;
                return;
            },
            countSectionError(section){
                if(!this.errors || !this.errors.items || !this.errors.items.length) return false;
                return this.errors.items.filter(i => i.scope === section).length;
            },
            calculateBilledMonths(){
                const selectedPromotions = this.form.promotions;
                //INC-3529
                
                const maxRequiredMonthsInPromotions = Math.max(...selectedPromotions.map(p => p.Promotion.required_months ? p.Promotion.required_months : 0), 0);
                const minNoOfAdditionalMonths = maxRequiredMonthsInPromotions >= 1 ? maxRequiredMonthsInPromotions : 0;
                // let payment_cycle = {
                //   label: null,
                //   period: 0
                // };
                // if(this.form.payment_cycle){
                //   payment_cycle = this.payment_cycle_options.find(o => o.label.toLowerCase() == this.form.payment_cycle.toLowerCase());
                // }
                // hack to make the
                let billedMonths = 0;
                
                billedMonths = minNoOfAdditionalMonths;
                
                this.$store.commit('onBoardingStore/setBilledMonths', billedMonths);
                this.$store.commit('onBoardingStore/setMinBilledMonths', billedMonths);
        
                
                

            },
            

          showNotificationModal(key){
            this.notificationModalType = key;
            if (key === 'edit_bill_day' && !this.hasPermission('edit_bill_day_onboarding_process')) {     
              this.notificationModal = true;
            }else if(key === 'edit_autopay' && !this.hasPermission('edit_autopay_in_movein_flow')){      		
              this.notificationModal = true;
            }
          },
          updateBillDay(date){
            let profile = this.currentBillingProfile(this.contact?.id, this.property?.id)
            if(!profile && this.unit_template?.bill_day === LEASE_CONFIGURATION.BILL_DAY.ANNIVERSARY){
              this.form.bill_day = moment(date).date();
            } else if(profile){
              this.form.bill_day = profile?.bill_day;
            }
          },
          setApwFlag(){
              if(!this.apwPlans?.reservationPlan?.plan?.days){
                this.showAPWDates = false
              }
              else{
                this.showAPWDates = true
                this.apwEndDate = this.apwPlans?.reservationPlan?.plan?.days > this.apwPlans?.rentalPlan?.plan?.days ? moment().add(this.apwPlans?.reservationPlan?.plan?.days, 'days').format('YYYY-MM-DD') : moment().add(this.apwPlans?.rentalPlan?.plan?.days, 'days').format('YYYY-MM-DD');
                this.minDate = moment().format('YYYY-MM-DD')

              }
            },
          async getCutOffTimeStatus(){
            let property_query=''
            if(this.property.id){
                property_query = `&property_id=${this.property.id}`
            }
            let response = await api.get(this.$app, api.SETTINGS + `apw-details?category=leadManagement${property_query}`)         
            if(response?.settings[0]?.value == 1 && this.showAPWDates){ 
              const response = await api.get(this, api.APW_SETTING + 'cut-off-time/' + this.property.id)
              this.inStoreTime = this.convertToAMPM(response[0].in_store_time)
            }
            else if(response?.settings[0]?.value == 1){ // fetch company level cut off time
              const response = await api.get(this, api.APW_SETTING + 'cut-off-time/')
              this.inStoreTime = this.convertToAMPM(response[0].in_store_time)
            }
          },
          convertToAMPM(utcTimestamp) {
            const date = new Date(parseInt(utcTimestamp, 10));

            const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const options = {
                                timeZone: systemTimeZone,
                                hour: "numeric", // No leading zero for hours
                                minute: "2-digit", // Two-digit minutes
                                hour12: true, // AM/PM format
                            };

            return new Intl.DateTimeFormat("en-US", options).format(date);
          },
          isBeforeTime(time){
            return moment(time, 'h:mm A').isBefore(moment())
          }

        },
        watch:{
            "form.promotions": {
                handler: function() {

                  this.calculateBilledMonths();
                 
                  
                 
                 
                },
                deep: true
            },
            "form.billing_cycle_id": {
                handler: function() {
                  if(this.form?.billing_cycle_id){
                    this.form.promotions = [];
                  }
                },
                deep: true
            },
            form: {
                async handler() {
                  try{
                    this.errorOut = false;
                    await this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...this.form });
                    let err = await this.$store.dispatch('onBoardingStore/getEstimate', { config:true, unit: this.configuration_unit,isNational:this.isNational, syncServices: true, contactNA : this.contact_na});
                    if(!!err){
                      this.$emit("setOverlayAndSendLinkButtonFlag",false)
                    }else{
                      this.$emit("setOverlayAndSendLinkButtonFlag",true)
                    }
                    
                  }catch(err){
                    this.errorOut = true;
                    this.showMessageNotification({ type: 'error', description: err });
                  }

                },
                deep : true
            },
            // These dont relate to anything
            // endDate() {},
            // leaseType() {}
        },
        destroyed() {
          EventBus.$emit("leaseInfoComponentDestroyed")
        },
        
    }

</script>

<style lang="scss">
  .lease-info .datepicker.dropdown-menu {
    z-index: 1000 !important;
  }

  .lease-info .v-expansion-panel{
    border: 1px solid #E1E6EA;
  }

  /*
  .lease-info .v-expansion-panel:before { box-shadow: none !important; }
  */

  .lease-info .v-label {
    font-size: 14px;
  }

  .lease-info .v-select__selection {
    font-size: 14px;
  }

  .lease-info .v-text-field.v-input--dense .v-input__prepend-inner .v-input__icon > .v-icon, .v-text-field.v-input--dense .v-input__append-inner .v-input__icon > .v-icon {
    margin-top: 0;
  }

  .unit-section{
    padding: 11px 24px 100px 24px;
  }
  .new-tenant-walkthrough-footer{
    // padding: 3px 0 3px 0;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }
  .apw-days-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 10px;
  }
</style>

